@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Josefin+Sans:wght@100&family=Nova+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiko&display=swap');

$font-1: 'Amiko', sans-serif;
$font-2: 'Dosis', sans-serif;
$font-3: 'Josefin Sans', serif;

// $color-1: #2a1d52;
// $color-2: #6C77F4;
// $color-3: rgb(99, 164, 233);
// $color-4: #4FEDD2;
// $color-5: #36D0EA;
// $white: #ddd;
// $black: #333;


// $color-1: #22577A;
// $color-2: #38A3A5;
// $color-3: rgb(53, 223, 141);
// $color-4: #57CC99;
// $color-5: #80ED99;
// $white: #ddd;
// $black: #333;

// $color-1: #22577A;
// $color-2: #388da5;
// $color-3: rgb(91, 164, 213);
// $color-4: #3e81b1ce;
// $color-5: #67b4d5;
// $white: #ddd;
// $black: #333;


$color-1: #22577A;
$color-2: #388da5;
$color-3: rgb(91, 164, 213);
$color-4: #3e81b1;
$color-5: #67b4d5;
$white: #ddd;
$black: #333;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$minHeight: 95vh;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center; 
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 1rem auto;
  max-width: 1200px;
  font-family: $font-1;
  color: $black;
}
button {
  cursor: pointer;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $black;
  cursor: pointer;
}
a:visited {
  color:$black;
}
.home, .knowledges, .portfolio, .contact {
  display: flex;

  @media screen and (max-width: 850px){
    display: block !important;
  }
}
.homeContent, .knowledgesContent, .portfolioContent, .contactContent {
  margin-left: .4rem;

  @media screen and (max-width: 850px){
    margin-left: 0 !important;
  }
}